<template>
  <span class="text-output">{{ value || '-' }}</span>
</template>

<script>
import outputMixin from './outputMixin'

export default {
  mixins: [outputMixin]
}
</script>
